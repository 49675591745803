import React from "react";
import {useDispatch, useSelector} from "react-redux";
import methods from "../data/methods.json";
import { setMethod } from "../store/method.slice";

function PaymentMethods() {
  const dispatch = useDispatch();
  const { payment } = useSelector((store) => store.method);


  const handleSelect = (method) => {
    dispatch(setMethod(method));
  };

  return (
    <main  className="vh-100 d-flex justify-content-center align-items-center">
      <div className="w-25">
        <ul className="list-group list-group-flush rounded shadow">
          <li className="list-group-item d-flex justify-content-between align-items-start">
            <div className="ms-2 me-auto py-3 d-flex justify-content-between align-items-start">
              <div className="p-2 me-4 rounded">
                <svg width="22" height="22" viewBox="0 0 47 38" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M47 19C47 29.4934 38.7217 38 28.5098 38C18.298 38 28.6215 29.4934 28.6215 19C28.6215 8.50659 18.298 0 28.5098 0C38.7217 0 47 8.50659 47 19Z" fill="#6C9EFF" fill-opacity="0.6"/>
                  <path d="M27 19C27 26.732 20.8812 33 13.3333 33C5.78546 33 13.4159 26.732 13.4159 19C13.4159 11.268 5.78546 5 13.3333 5C20.8812 5 27 11.268 27 19Z" fill="#6C9EFF" fill-opacity="0.6"/>
                  <path d="M12 19C12 24.5228 7.68087 29 2.35295 29C-2.97497 29 2.41122 24.5228 2.41122 19C2.41122 13.4772 -2.97497 9 2.35295 9C7.68087 9 12 13.4772 12 19Z" fill="#6C9EFF" fill-opacity="0.6"/>
                </svg>
              </div>
              <div>
                <span>You are paying</span>
                <h3 className="h4">SAT {payment?.data.amount}</h3>
              </div>
            </div>
          </li>

          {methods.map((method, i) => (
            <li
              key={i}
              className="list-group-item d-flex justify-content-between align-items-center pointer"
              onClick={() => handleSelect(method.method)}
            >
              <div className="ms-2 me-auto py-3 d-flex justify-content-between align-items-start">
                <div className="bg-gray p-2 me-4 rounded">
                  <img src={method.icon} width="22" alt="" />
                </div>
                <div>
                  <h3 className="h4">{method.name}</h3>
                  <span>{method.description}</span>
                </div>
              </div>
              <img src="../assets/arrow-forward.svg" className="ms-2" width="12" alt="" />
            </li>
          ))}
        </ul>
      </div>
    </main>
  );
}

export default PaymentMethods;
