import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {generateInvoice, setMethod} from "../store/method.slice";
import QRCode from "react-qr-code";

function QRPaymentMethod() {
  const dispatch = useDispatch();
  const {payment, subLoading, loading, invoice, paymentStatus } = useSelector((store) => store.method);

  console.log("payment is = ", payment)
  useEffect(() => {
    dispatch(generateInvoice(payment.data.public_key, {amt: parseInt(payment.data.amount), trx_ref: payment.data.reference},
        payment.data.redirect_url))
  }, [payment])

  const goBack = () => {
    dispatch(setMethod(""));
  };

  return (
    <main className="vh-100 d-flex justify-content-center align-items-center">
      <div className="w-25">
        <ul className="list-group list-group-flush rounded shadow">
          <li className="list-group-item d-flex justify-content-between align-items-start">
            <div className="ms-2 me-auto py-3 d-flex justify-content-between align-items-start">
              <div className="p-2 me-4 rounded">
                <svg width="22" height="22" viewBox="0 0 47 38" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M47 19C47 29.4934 38.7217 38 28.5098 38C18.298 38 28.6215 29.4934 28.6215 19C28.6215 8.50659 18.298 0 28.5098 0C38.7217 0 47 8.50659 47 19Z" fill="#6C9EFF" fill-opacity="0.6"/>
                  <path d="M27 19C27 26.732 20.8812 33 13.3333 33C5.78546 33 13.4159 26.732 13.4159 19C13.4159 11.268 5.78546 5 13.3333 5C20.8812 5 27 11.268 27 19Z" fill="#6C9EFF" fill-opacity="0.6"/>
                  <path d="M12 19C12 24.5228 7.68087 29 2.35295 29C-2.97497 29 2.41122 24.5228 2.41122 19C2.41122 13.4772 -2.97497 9 2.35295 9C7.68087 9 12 13.4772 12 19Z" fill="#6C9EFF" fill-opacity="0.6"/>
                </svg>
              </div>
              <div>
                <span>You are paying</span>
                <h3 className="h4">SAT {payment.amount}</h3>
              </div>
            </div>
          </li>
          <li className="list-group-item d-flex justify-content-between align-items-center">
            <div className="ms-2 me-auto py-3">
              <div className="mb-5">
                <button style={{background:"#0C121E",color:"#D1DAFD"}} className="button d-flex justify-content-between align-items-center" onClick={goBack}>
                  <img src="../assets/back-arrow.svg" className="me-4" width="16" alt="" />
                  Change payment method
                </button>
              </div>

              <div className="mb-2">
                <h3 className="h4">Pay with Lighting</h3>
                <span>Scan the be qr code below with any lightning wallet of your choice to pay</span>
              </div>
              <div className="d-block mx-auto">
                {loading ?  <img src="../assets/load.gif" width="22" alt="" /> : <></>}
                {invoice != null ? <QRCode value={invoice?.r_hash} /> : <></>}
              </div>
            </div>
          </li>

          <li className="list-group-item d-flex justify-content-between align-items-center">

              {subLoading ?
                  <div className="ms-2 me-auto py-3 d-flex justify-content-between align-items-center">
                    <div className="p-2 me-2">
                      <img src="../assets/load.gif" width="22" alt="" />
                    </div>

                    <div>
                      <span>Confirming transaction status</span>
                    </div>

                  </div> : <></>
              }

            {!subLoading && paymentStatus != null ?
                <div className="ms-2 me-auto py-3 d-flex justify-content-between align-items-center">
                  <div>
                    <span>{paymentStatus.message}</span>
                  </div>
                </div> : <></>
            }

          </li>
        </ul>
      </div>
    </main>
  );
}

export default QRPaymentMethod;
