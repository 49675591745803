import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const baseUrl = process.env.REACT_APP_BASE_URL

const initialState = {
  method: "",
  payment: null,
  error: null,
  loading: true,
  invoice: null,
  subLoading: true,
  paymentStatus: null
};

export const methodSlice = createSlice({
  name: "method",
  initialState,
  reducers: {
    setMethod: (state, action) => {
      state.method = action.payload;
    },
    resetMethod: (state) => {
      state.method = "";
    },
    setPayment: (state, action) => {
      state.payment = action.payload;
      state.loading = false;
    },
    setPaymentStatus: (state, action) => {
      state.paymentStatus = action.payload;
      state.subLoading = false;
    },
    setInvoice: (state, action) => {
      state.invoice = action.payload;
      state.loading = false;
    },
    setError: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    isLoading: (state) => {
      state.loading = true;
    },
    isSubLoading: (state) => {
      state.subLoading = true;
    },
  },
});

export const { setMethod, resetMethod, setPayment,
  setInvoice, setError,isLoading, isSubLoading, setPaymentStatus} = methodSlice.actions;

export const generateInvoice = (key, data, redirect_url) => (dispatch) => {


  const config = {
    headers:{
      "X-API-KEY": key,
    }
  };

  dispatch(isLoading)

  return axios
      .post(`${baseUrl}/api/v1/invoices`, data, config)
      .then((response) => {
        console.log("invoice info", response.data?.data)
        dispatch(setInvoice(response.data?.data));
        dispatch(subscribeInvoice(key, {r_hash: response.data?.data.r_hash}, redirect_url))
      })
      .catch((err) => {
        if (err.response && err.response.data) {
          dispatch(setError(err.response.data.message))
        }
      });
};

export const subscribeInvoice = (key, data, redirect_url) => (dispatch) => {

  const config = {
    headers:{
      "X-API-KEY": key,
    }
  };

  dispatch(isSubLoading)

  return axios
      .post(`${baseUrl}/api/v1/invoices/subscribe`, data, config)
      .then((response) => {
        dispatch(setPaymentStatus(response.data))
        let url = redirect_url + "?status=success"
        window.location.replace(url);
      })
      .catch((err) => {
        if (err.response && err.response.data) {
          dispatch(setPaymentStatus(err.response.data))
          let url = redirect_url + "?status=failed"
          window.location.replace(url);
        }
      });
};

export const getPayment = (ref) => (dispatch) => {

  dispatch(isLoading)

  return axios
      .get(`${baseUrl}/api/v1/payments/${ref}`)
      .then((response) => {
        dispatch(setPayment(response.data))
      })
      .catch((err) => {
        if (err.response && err.response.data) {
          dispatch (setError(err.response.data))
        }
      });
};
export default methodSlice.reducer;
