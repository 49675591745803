import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import QRPaymentMethod from "./QRPaymentMethod";
import PaymentMethods from "./PaymentMethods";
import {getPayment, setPayment} from "../store/method.slice";
import Loading from "./Loading";


function Home() {
  const { method,loading, error } = useSelector((store) => store.method);
  const dispatch = useDispatch();

  const search = window.location.search;
  const params = new URLSearchParams(search);
  const ref = params.get('ref');

  useEffect(() => {
    dispatch(getPayment(ref))
  }, [])



  return (
    <main data-testid="app">
      {(loading || error !== null) && <Loading />}
      {!loading && error === null && method === "" && <PaymentMethods />}
      {!loading && error === null && method === "qr" && <QRPaymentMethod />}
    </main>
  );
}

export default Home;
