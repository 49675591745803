import React from "react";
import {useDispatch, useSelector} from "react-redux";
import methods from "../data/methods.json";
import { setMethod } from "../store/method.slice";

function Loading() {
  const {loading, error} = useSelector((store) => store.method);

  console.log("loading is", loading)
  return (
    <main className="vh-100 d-flex justify-content-center align-items-center">
      <div className="w-25">
        {loading  && <img src="../assets/load.gif" width="100" alt="Loading..." />}
        {!loading && error !== null && <p>An error occurred, error is {error.message}</p>}

      </div>
    </main>
  );
}

export default Loading;
