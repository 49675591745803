import React from "react";
import { Provider } from "react-redux";
import Home from "./components/Home";
import { CookiesProvider } from "react-cookie";
import { store } from "./store";

function App() {
  return (
          <Provider store={store}>
              <Home />
          </Provider>
  );
}

export default App;
